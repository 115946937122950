<template>
    <div>
        <b-row>
            <b-col cols="12" style="height: 100%">
                <heat-map></heat-map>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import HeatMap from "./components/heatmap.vue";
export default {
    name: "diagnosisRisk",
    components: {
        HeatMap,
    },
};
</script>

<style lang="scss">
@import "@/@core/scss/vue/libs/vue-flatpicker";
@import "@/@core/scss/vue/libs/vue-select";
</style>
